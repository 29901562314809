
import React from "react"

import PickuppointForm from "../../components/negosyonow/forms/pickuppoint";

const formFields = [
	{"label":"Code", "field": "nnpickup_code", "value": "", "input": "textbox", "mode": "unique"},
	{"label":"Name", "field": "nnpickup_name", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Type", "field": "onsorderformfulfillment_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":4,"display":"Bus Shipping"},
		{"value":2,"display":"Delivery"},
		{"value":1,"display":"Others"},
		{"value":3,"display":"Pick up"},
	]},
	{"label":"GPS Location", "field": "nnpickup_gpsloc", "value": "", "input": "textbox", "mode": "required", "info":"14.600001:121.000001"},
	{"label":"Default Fee", "field": "nnpickup_minamount", "value": "", "input": "currency", "mode": "required"}
];

const PickuppointFullPage = ({location}) => {

	return <PickuppointForm
				location={location}
				allowadd={true}
				allowdelete={false}
				custombasicformfields={formFields}
			/>


}


export default PickuppointFullPage;
